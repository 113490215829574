<template>
  <v-bottom-navigation
    class="navBar flex-container space-between"
    v-model="menuPage"
    app
    color="blue darken-2"
    height="80"
  >
    <!-- <v-btn to="/tasks" :ripple="false" class="navBtn">
      <span style="margin-top: 5px; font-size: 10px">Tasks</span>
      <v-icon style="font-size: 30px">mdi-clipboard-check</v-icon>
    </v-btn> -->
    <v-btn to="/buildings" :ripple="false" class="navBtn">
      <span style="margin-top: 5px; font-size: 10px">Buildings</span>
      <v-icon style="font-size: 30px">mdi-home</v-icon>
    </v-btn>
    <v-btn
      to="/calendar"
      :ripple="false"
      class="navBtn"
      v-if="this.$store.state.userIsAdmin"
    >
      <span style="margin-top: 5px; font-size: 10px">Calendar</span>
      <v-icon style="font-size: 30px">mdi-calendar</v-icon>
    </v-btn>
    <v-btn
      to="/kitchen"
      :ripple="false"
      class="navBtn"
      v-if="this.$store.state.userIsAdmin"
    >
      <span style="margin-top: 5px; font-size: 10px">Kitchen</span>
      <v-icon style="font-size: 30px">mdi-food-turkey</v-icon>
    </v-btn>
    <v-btn to="/docs" :ripple="false" class="navBtn">
      <span style="margin-top: 5px; font-size: 10px">Docs</span>
      <v-icon style="font-size: 30px">mdi-bookshelf</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "BottomNavigation",
  data: function () {
    return {
      menuPage: 0,
    };
  },
};
</script>

<style>
.navBtn {
  margin-top: -15px !important;
  background-color: transparent !important;
}
.navBtn:active {
  background-color: transparent !important;
}
.navBtn:before {
  background-color: transparent !important;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .navBar {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8) !important;
    -webkit-box-shadow: 0px -0.1px #888888 !important;
    -moz-box-shadow: 0px -0.1px #888888 !important;
    box-shadow: 0px -0.1px #888888 !important;
  }
}
</style>
